/* THIS IS FOR THE GET YOUR INVITE PAGE */

import React from "react";
import Helmet from "react-helmet";
import window from "global";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import bookImage from "../../assets/img/dad.jpg";

import blogSubscribePageStyle from "assets/jss/material-kit-react/views/blogSubscribePageStyle.jsx";

import WorkSection from "./Sections/WorkSection.jsx";


const bookmerlinImage = window.innerWidth >= 650 ? bookImage : bookImage;

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
           color="transparent"
           brand="BookMerlin"
           rightLinks={<HeaderLinks />}
           fixed
           changeColorOnScroll={{
             height: 50,
             color: "#009688"
           }}
           {...rest}
        />
        <div className={classes.img} style={{backgroundImage: `url(${bookmerlinImage})`}}>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classNames(classes.main, classes.mainRaised)}>
                  <div className={classes.container}>
                    <WorkSection />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(blogSubscribePageStyle)(LandingPage);
